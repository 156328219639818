.profileWrapper{
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (min-width: 961px) {
    .profileWrapper{
        margin-right: 100px;
    }
}

@media screen and (max-width: 960px) {
    .profileWrapper{
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

.profileContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    width: 210px;
    height: 215px;
    flex-shrink: 0;
}

@media screen and (max-width: 960px) {
    .profileContainer{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin-right: 30px;
    }
}

@media screen and (max-width: 375px) {
    .intro{
        flex-grow: 1;
    }
}

@media screen and (max-width: 350px) {
    .profileContainer{
        margin-right: 10px;
    }
}

.profileImage{
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 195px;
    max-width: 100%;
}

@media screen and (max-width: 960px) {
    .profileImage{
        bottom: -15px;
    }
}

.profileRotating{
    z-index: 2;
    position: absolute;
    bottom: -150px;
    left: 50%;
    animation: forms-infinite-spin infinite 50s linear;
}

@media screen and (max-width: 960px) {
    .profileRotating{
        display: none;
    }
}

.profileGradient {
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    height: 170px;
    background: linear-gradient(#08c8f6, #4d5dfb);
}

@keyframes forms-infinite-spin {
    from {
        transform: translate(-50%,0) rotate(0deg);
    }
    to {
        transform: translate(-50%,0) rotate(360deg);
    }
  }

.profileTitle{
    color: #fff;
    font-size: 1.3em;
    font-weight: 300;
    margin-bottom: 10px;
}

@media screen and (min-width: 961px) {
    .profileTitle{
        margin-top: 10px;
    }
}

.profileSubtitle{
    color: #fff;
    font-size: .8em;
    font-weight: 300;
    margin-bottom: 10px;
}