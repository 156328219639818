.button{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 45px;
    padding: 0 50px;
    align-self: center;
    -webkit-appearance: none;
    background: linear-gradient(to left, #08c8f6, #4d5dfb);
    color: #fff;
    text-transform: uppercase;
    font-size: .9em;
    border: 0;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    transform: scale(1);
    transition: all .1s ease-in-out;
}

@media screen and (max-width: 480px) {
    .button{
        margin-bottom: 20px;
        padding: 0;
        width: 100%;
    }
}

@media screen and (min-width: 481px) {
    .button{
        margin-top: 50px;
    }
}

.button:hover{
    transform: scale(1.1);
}