.container{
    line-height: 1.3;
    font-size: 1.1em;
}

.container p{
    display: block;
    margin-bottom: 20px;
}

.container p,
.container span {
    font-weight: lighter;
}

.container strong {
    font-weight: 500;
}

.container p,
.container li {
    margin-bottom: 20px;
}

.container ul{
    list-style-type: circle;
    padding-left: 20px;
}

.container a {
    color: #08c8f6;
}

.container a:visited,
.container a:active {
    color: #4d5dfb;
}