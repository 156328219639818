.socialMediaContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.socialMediaSvg{
    height: 15px;
    margin-right: 18px;
    width: 20px;
}