.container ul{
    list-style-type: circle;
    padding-left: 15px;
    font-size: 17.6px;
}

.container li{
    line-height: 1.3;
    margin-bottom: 20px;
}

.container strong {
    font-weight: 500;
}

.container i{
    margin-right: -3px;
}

.container a {
    color: #08c8f6;
}

.container a:visited,
.container a:active {
    color: #4d5dfb;
}