.list{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 960px) {
    .list{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 768px) {
    .list{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 620px) {
    .list{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 480px) {
    .list{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 370px) {
    .list{
        grid-gap: 20px;
    }
}

.item{
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    transition-property: all;
    width: 100%;
    padding-bottom: 100%;
    transition: transform .1s ease-in-out, opacity .1s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.mounted{
    visibility: visible;
    opacity: 1;
}

.icon{
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .1s ease-in-out;
}

.item:nth-child(8) .icon {
    height: auto;
    width: 50px;
}

.title{
    position: absolute;
    top: calc(50% + 20px);
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .1s ease-in-out;
    width: 100%;
    text-align: center;
    opacity: 0;
    font-size: .75em;
}

.background{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;

    border: 1px solid #808080;
    background-color: rgba(187, 187, 187, 0.05);
    border-radius: 10px;
    transform: scale(1);
    transition: all .1s linear;
    animation-fill-mode: both;
}

.item:hover .title{
    opacity: 1;
    top: calc(50% + 25px);
}

.item:hover .background{
    transform: scale(1.15);
}

.item:hover .icon{
    top: calc(50% - 10px);
}