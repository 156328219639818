.container{
    display: grid;
    grid-gap: 20px;
    margin-bottom: 40px;
}

.title{
    font-size: 1.2em;
    margin-bottom: 20px;
}

.link {
    display: block;
    text-align: center;
    color: #08c8f6;
    padding: 10px;
}

.link:visited,
.link:active {
    color: #4d5dfb;
}