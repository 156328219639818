.Page_container__Arl5G{
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
}

@media screen and (min-width: 961px) {
    .Page_container__Arl5G{
        top: 90px;
    }
}

@media screen and (max-width: 960px) {
    .Page_container__Arl5G{
        padding-bottom: 80px;
    }
}
.Widgets_list__3F-2w{
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media screen and (max-width: 960px) {
    .Widgets_list__3F-2w{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 768px) {
    .Widgets_list__3F-2w{
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 620px) {
    .Widgets_list__3F-2w{
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 480px) {
    .Widgets_list__3F-2w{
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width: 370px) {
    .Widgets_list__3F-2w{
        grid-gap: 20px;
    }
}

.Widgets_item__DhNFz{
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    transition-duration: 1s;
    transition-timing-function: ease-in-out;
    transition-property: all;
    width: 100%;
    padding-bottom: 100%;
    transition: opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
    transition: transform .1s ease-in-out, opacity .1s ease-in-out;
    transition: transform .1s ease-in-out, opacity .1s ease-in-out, -webkit-transform .1s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.Widgets_mounted__1C7wX{
    visibility: visible;
    opacity: 1;
}

.Widgets_icon__1TXya{
    height: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: all .1s ease-in-out;
}

.Widgets_item__DhNFz:nth-child(8) .Widgets_icon__1TXya {
    height: auto;
    width: 50px;
}

.Widgets_title__k3IQo{
    position: absolute;
    top: calc(50% + 20px);
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    transition: all .1s ease-in-out;
    width: 100%;
    text-align: center;
    opacity: 0;
    font-size: .75em;
}

.Widgets_background__s6pwA{
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;

    border: 1px solid #808080;
    background-color: rgba(187, 187, 187, 0.05);
    border-radius: 10px;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all .1s linear;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}

.Widgets_item__DhNFz:hover .Widgets_title__k3IQo{
    opacity: 1;
    top: calc(50% + 25px);
}

.Widgets_item__DhNFz:hover .Widgets_background__s6pwA{
    -webkit-transform: scale(1.15);
            transform: scale(1.15);
}

.Widgets_item__DhNFz:hover .Widgets_icon__1TXya{
    top: calc(50% - 10px);
}
.EmailButton_button__3DxP3{
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 45px;
    padding: 0 50px;
    align-self: center;
    -webkit-appearance: none;
    background: linear-gradient(to left, #08c8f6, #4d5dfb);
    color: #fff;
    text-transform: uppercase;
    font-size: .9em;
    border: 0;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all .1s ease-in-out;
}

@media screen and (max-width: 480px) {
    .EmailButton_button__3DxP3{
        margin-bottom: 20px;
        padding: 0;
        width: 100%;
    }
}

@media screen and (min-width: 481px) {
    .EmailButton_button__3DxP3{
        margin-top: 50px;
    }
}

.EmailButton_button__3DxP3:hover{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.Skills_container__39EN2{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 480px) {
    .Skills_container__39EN2{
        flex-direction: column-reverse;
    }
}
.Thoughts_container__V05GK{
    line-height: 1.3;
    font-size: 1.1em;
}

.Thoughts_container__V05GK p{
    display: block;
    margin-bottom: 20px;
}

.Thoughts_container__V05GK p,
.Thoughts_container__V05GK span {
    font-weight: lighter;
}

.Thoughts_container__V05GK strong {
    font-weight: 500;
}

.Thoughts_container__V05GK p,
.Thoughts_container__V05GK li {
    margin-bottom: 20px;
}

.Thoughts_container__V05GK ul{
    list-style-type: circle;
    padding-left: 20px;
}

.Thoughts_container__V05GK a {
    color: #08c8f6;
}

.Thoughts_container__V05GK a:visited,
.Thoughts_container__V05GK a:active {
    color: #4d5dfb;
}
.Card_container__1y1EE {
    display: block;
    border-radius: 4px;
    border: 1px solid #808080;
    background: rgba(187, 187, 187, 0.05);
    padding: 15px;
    transition: all .1s ease-in-out;
    color: white;
    text-decoration: none;
}

.Card_header__8azLw {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.Card_container__1y1EE:hover{
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}

.Card_subtitle__3RB7X {
    font-size: .8em;
    color: rgb(184, 184, 184);
}

.Card_summary__2K_lb {
    margin-top: 15px;
    line-height: 1.25;
}

.Card_list__19NDh{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;
    flex-wrap: wrap;
    align-items: center;
}

.Card_item__3WxaN{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Card_item__3WxaN + .Card_item__3WxaN:before{
    content: '|';
    padding: 0 10px;
    font-size: .7em;
}
.Work_container__DVgvl{
    display: grid;
    grid-gap: 20px;
    margin-bottom: 40px;
}

.Work_title__POaRk{
    font-size: 1.2em;
    margin-bottom: 20px;
}

.Work_link__284Yl {
    display: block;
    text-align: center;
    color: #08c8f6;
    padding: 10px;
}

.Work_link__284Yl:visited,
.Work_link__284Yl:active {
    color: #4d5dfb;
}
.Setup_container__21Z03 ul{
    list-style-type: circle;
    padding-left: 15px;
    font-size: 17.6px;
}

.Setup_container__21Z03 li{
    line-height: 1.3;
    margin-bottom: 20px;
}

.Setup_container__21Z03 strong {
    font-weight: 500;
}

.Setup_container__21Z03 i{
    margin-right: -3px;
}

.Setup_container__21Z03 a {
    color: #08c8f6;
}

.Setup_container__21Z03 a:visited,
.Setup_container__21Z03 a:active {
    color: #4d5dfb;
}
.Container_container__1LEDW{
    max-width: 900px;
    padding: 0 20px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 960px) {
    .Container_container__1LEDW{
        flex-direction: column;
    }   
}
.Header_headerList__1GWE6{
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .Header_headerList__1GWE6 {
        position: fixed;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        justify-content: space-between;
    }
}

.Header_headerLink__3jb9C{
    -webkit-appearance: none;
    position: relative;
    display: block;
    color: rgba(255,255,255, .5);
    text-decoration: none;
    transition: all .1s ease-in-out;
}

@media screen and (max-width: 960px) {
    .Header_headerLink__3jb9C {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        background: #383838;

        font-size: .6em;
        text-align: center;
        flex-grow: 1;
    }
}

@media screen and (min-width: 961px) {
    .Header_headerLink__3jb9C {
        font-size: 1em;
        padding: 20px 20px 15px 0;
        margin-right: 20px;
    }
}

.Header_headerLink__3jb9C:hover{
    color: rgba(255,255,255, 1);
}


@media screen and (min-width: 961px) {
    .Header_headerLink__3jb9C::before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        -webkit-transform: translateY(-100%);
                transform: translateY(-100%);
        height: 5px;
        width: 0;
        background: linear-gradient(to left, #08c8f6, #4d5dfb);
        transition: 300ms ease-in-out;
    }
}

@media screen and (max-width: 960px) {
    .Header_headerItem__1akbo {
        flex-grow: 1;
    }
}
.header-link-active{
    color: rgba(255,255,255,1);
}

@media screen and (max-width: 960px) {
    .header-link-active{
        background: #676767;
    }   
}

.header-link-active:before{
    width: 100%;
}
.Profile_profileWrapper__2LLFq{
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    margin-top: 20px;
    margin-bottom: 20px;
}

@media screen and (min-width: 961px) {
    .Profile_profileWrapper__2LLFq{
        margin-right: 100px;
    }
}

@media screen and (max-width: 960px) {
    .Profile_profileWrapper__2LLFq{
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}

.Profile_profileContainer__2QhT9{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    position: relative;
    width: 210px;
    height: 215px;
    flex-shrink: 0;
}

@media screen and (max-width: 960px) {
    .Profile_profileContainer__2QhT9{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        margin-right: 30px;
    }
}

@media screen and (max-width: 375px) {
    .Profile_intro__310gp{
        flex-grow: 1;
    }
}

@media screen and (max-width: 350px) {
    .Profile_profileContainer__2QhT9{
        margin-right: 10px;
    }
}

.Profile_profileImage__hcCU6{
    z-index: 3;
    position: absolute;
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 195px;
    max-width: 100%;
}

@media screen and (max-width: 960px) {
    .Profile_profileImage__hcCU6{
        bottom: -15px;
    }
}

.Profile_profileRotating__37WBb{
    z-index: 2;
    position: absolute;
    bottom: -150px;
    left: 50%;
    -webkit-animation: Profile_forms-infinite-spin__2j1TQ infinite 50s linear;
            animation: Profile_forms-infinite-spin__2j1TQ infinite 50s linear;
}

@media screen and (max-width: 960px) {
    .Profile_profileRotating__37WBb{
        display: none;
    }
}

.Profile_profileGradient__3mg_2 {
    position: relative;
    overflow: hidden;
    z-index: 1;
    width: 100%;
    height: 170px;
    background: linear-gradient(#08c8f6, #4d5dfb);
}

@-webkit-keyframes Profile_forms-infinite-spin__2j1TQ {
    from {
        -webkit-transform: translate(-50%,0) rotate(0deg);
                transform: translate(-50%,0) rotate(0deg);
    }
    to {
        -webkit-transform: translate(-50%,0) rotate(360deg);
                transform: translate(-50%,0) rotate(360deg);
    }
  }

@keyframes Profile_forms-infinite-spin__2j1TQ {
    from {
        -webkit-transform: translate(-50%,0) rotate(0deg);
                transform: translate(-50%,0) rotate(0deg);
    }
    to {
        -webkit-transform: translate(-50%,0) rotate(360deg);
                transform: translate(-50%,0) rotate(360deg);
    }
  }

.Profile_profileTitle__j42OV{
    color: #fff;
    font-size: 1.3em;
    font-weight: 300;
    margin-bottom: 10px;
}

@media screen and (min-width: 961px) {
    .Profile_profileTitle__j42OV{
        margin-top: 10px;
    }
}

.Profile_profileSubtitle__1RdZM{
    color: #fff;
    font-size: .8em;
    font-weight: 300;
    margin-bottom: 10px;
}
.SelectLanguage_container__3xKJO{
    position: relative;
    width: 100px;
    overflow: hidden;
}

@media screen and (max-width: 960px) {
    .SelectLanguage_container__3xKJO{
        margin-bottom: 10px;
    }
}
@media screen and (min-width: 961px) {
    .SelectLanguage_container__3xKJO{
        margin-top: 10px;
    }
}

.SelectLanguage_container__3xKJO:before{
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    border-style: solid;
	border-width: 0.1em 0.1em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 30px;
	vertical-align: top;
    width: 0.45em;
    -webkit-transform: translateY(-50%) rotate(135deg);
            transform: translateY(-50%) rotate(135deg);
    border-color: #fff;
}

.SelectLanguage_input__2yNuX{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 0;
    width: 100%;
    height: 15px;
    outline: none;
}

.SelectLanguage_input__2yNuX option{
    font-size: 16px;
    color: #000;
}

.SelectLanguage_input__2yNuX:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.SelectLanguage_input__2yNuX:focus{
    outline: 0;
}

.SelectLanguage_flag__2sbjc svg{
    height: 15px;
}

.SocialMedia_socialMediaContainer__1QiFm{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.SocialMedia_socialMediaSvg__1FqWs{
    height: 15px;
    margin-right: 18px;
    width: 20px;
}
.PageContainer_container__1_wjW{
    position: relative;
    height: 530px;
    flex-grow: 1;
    width: 100%;
}
/* Defaults */
html, body, #root{
  height: 100%;
}

body {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1C2329;
}

i{
  font-style: italic;
}

/* Transitions */

.fade-enter {
  opacity: 0;
  -webkit-transform: translate3d(0, 8px, 0);
          transform: translate3d(0, 8px, 0);
}

.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.fade-exit {
  opacity: 0;
}
