.container{
    position: absolute;
    left: 0;
    right: 0;
    color: #fff;
}

@media screen and (min-width: 961px) {
    .container{
        top: 90px;
    }
}

@media screen and (max-width: 960px) {
    .container{
        padding-bottom: 80px;
    }
}