/* Defaults */
html, body, #root{
  height: 100%;
}

body {
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1C2329;
}

i{
  font-style: italic;
}

/* Transitions */

.fade-enter {
  opacity: 0;
  transform: translate3d(0, 8px, 0);
}

.fade-enter-active {
  opacity: 1;
  transition: all 300ms ease-in;
  transform: translate3d(0, 0, 0);
}

.fade-exit {
  opacity: 0;
}