.container{
    position: relative;
    width: 100px;
    overflow: hidden;
}

@media screen and (max-width: 960px) {
    .container{
        margin-bottom: 10px;
    }
}
@media screen and (min-width: 961px) {
    .container{
        margin-top: 10px;
    }
}

.container:before{
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    border-style: solid;
	border-width: 0.1em 0.1em 0 0;
	content: '';
	display: inline-block;
	height: 0.45em;
	left: 30px;
	vertical-align: top;
    width: 0.45em;
    transform: translateY(-50%) rotate(135deg);
    border-color: #fff;
}

.input{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 0;
    width: 100%;
    height: 15px;
    outline: none;
}

.input option{
    font-size: 16px;
    color: #000;
}

.input:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
}

.input:focus{
    outline: 0;
}

.flag svg{
    height: 15px;
}
