.container{
    max-width: 900px;
    padding: 0 20px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
}

@media screen and (max-width: 960px) {
    .container{
        flex-direction: column;
    }   
}