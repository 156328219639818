.headerList{
    display: flex;
    flex-direction: row;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .headerList {
        position: fixed;
        z-index: 1;
        left: 0;
        bottom: 0;
        width: 100%;
        justify-content: space-between;
    }
}

.headerLink{
    -webkit-appearance: none;
    position: relative;
    display: block;
    color: rgba(255,255,255, .5);
    text-decoration: none;
    transition: all .1s ease-in-out;
}

@media screen and (max-width: 960px) {
    .headerLink {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        background: #383838;

        font-size: .6em;
        text-align: center;
        flex-grow: 1;
    }
}

@media screen and (min-width: 961px) {
    .headerLink {
        font-size: 1em;
        padding: 20px 20px 15px 0;
        margin-right: 20px;
    }
}

.headerLink:hover{
    color: rgba(255,255,255, 1);
}


@media screen and (min-width: 961px) {
    .headerLink::before{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translateY(-100%);
        height: 5px;
        width: 0;
        background: linear-gradient(to left, #08c8f6, #4d5dfb);
        transition: 300ms ease-in-out;
    }
}

@media screen and (max-width: 960px) {
    .headerItem {
        flex-grow: 1;
    }
}